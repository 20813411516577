
.stu-process-btns {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 37px;
    .process-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #1122D8;
        .iconfont {
            font-size: 20px;
        }
        color: #1122D8;
        cursor: pointer;
        &.btn-up {
            margin-right: 9px;
            span {
                margin-left: 8px;
            }
        }
        &.btn-down {
            i.iconfont {
                transform: rotate(180deg);
            }
            span {
                margin-right: 8px;
            }
        }
        &.disabled {
            border: 1px solid #E5E5E5;
            color: #E5E5E5;
            cursor: not-allowed;
        }
    }
    i.iconfont {
        font-size: 15px;
    }
}

